// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../User.bs.js";
import * as React from "react";
import * as NextIntl from "next-intl";
import * as WebsiteFooter from "../components/WebsiteFooter.bs.js";
import * as WebsiteHeader from "../components/WebsiteHeader.bs.js";
import * as WebsiteWrapper from "../components/WebsiteWrapper.bs.js";
import * as MessageErrorBig from "../components/MessageErrorBig.bs.js";

function PageError404(Props) {
  var t = NextIntl.useTranslations();
  var match = User.useOptionalUserQueryRef(undefined);
  var userOptionalQueryRef = match[0];
  return React.createElement(WebsiteWrapper.make, {
              userOptionalQueryRef: userOptionalQueryRef,
              children: null
            }, React.createElement(WebsiteHeader.make, {
                  userOptionalQueryRef: userOptionalQueryRef,
                  noCreate: true
                }), React.createElement(MessageErrorBig.make, {
                  title: t("_Error-404-title"),
                  details: t("_Error-404-details"),
                  fallback: t("_Error-404-fallback"),
                  fallbackUrl: "/"
                }), React.createElement(WebsiteFooter.make, {}));
}

var make = PageError404;

var $$default = PageError404;

export {
  make ,
  $$default ,
  $$default as default,
}
/* User Not a pure module */
