// Generated by ReScript, PLEASE EDIT WITH CARE

import * as T from "../T.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as LinkText from "./shareable/LinkText.bs.js";
import * as NextIntl from "next-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactNative from "react-native";
import * as ViewContained from "./shareable/ViewContained.bs.js";
import * as Font$ReactMultiversal from "react-multiversal/src/Font.bs.js";
import * as Spacer$ReactMultiversal from "react-multiversal/src/Spacer.bs.js";
import * as Predefined$ReactMultiversal from "react-multiversal/src/Predefined.bs.js";
import * as SpacedView$ReactMultiversal from "react-multiversal/src/SpacedView.bs.js";

function MessageErrorBig(Props) {
  var title = Props.title;
  var details = Props.details;
  var fallback = Props.fallback;
  var fallbackUrl = Props.fallbackUrl;
  var theme = Curry._2(T.useTheme, undefined, undefined);
  var t = NextIntl.useTranslations();
  return React.createElement(ReactNative.View, {
              style: [
                Predefined$ReactMultiversal.styles.flexGrow,
                theme.styles.back
              ],
              children: React.createElement(SpacedView$ReactMultiversal.make, {
                    vertical: /* None */7,
                    horizontal: /* S */4,
                    children: React.createElement(ViewContained.make, {
                          contentContainerStyle: Predefined$ReactMultiversal.styles.alignCenter,
                          children: null
                        }, React.createElement(Spacer$ReactMultiversal.make, {
                              size: /* XL */1
                            }), React.createElement(ReactNative.Text, {
                              children: Belt_Option.getWithDefault(title, t("-UnexpectedError")),
                              style: [
                                Font$ReactMultiversal.iosEm.title1,
                                theme.styles.text
                              ]
                            }), React.createElement(Spacer$ReactMultiversal.make, {
                              size: /* XL */1
                            }), Belt_Option.getWithDefault(Belt_Option.map(details, (function (details) {
                                    return React.createElement(ReactNative.Text, {
                                                children: details,
                                                style: [
                                                  Font$ReactMultiversal.ios.title3,
                                                  theme.styles.text
                                                ]
                                              });
                                  })), null), Belt_Option.getWithDefault(Belt_Option.map(fallback, (function (fallback) {
                                    return React.createElement(React.Fragment, undefined, React.createElement(Spacer$ReactMultiversal.make, {
                                                    size: /* L */2
                                                  }), React.createElement(LinkText.make, {
                                                    children: fallback,
                                                    href: Belt_Option.getWithDefault(fallbackUrl, "/"),
                                                    style: [
                                                      Font$ReactMultiversal.ios.body,
                                                      theme.styles.textLight1,
                                                      Font$ReactMultiversal.weight[300]
                                                    ]
                                                  }));
                                  })), null))
                  })
            });
}

var make = MessageErrorBig;

export {
  make ,
}
/* T Not a pure module */
