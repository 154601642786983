// export { getStaticPaths } from "src/pages/PageError404.bs.js";
// export { getStaticProps } from "src/pages/PageError404.bs.js";
import page from "src/pages/PageError404.bs.js";
export default page;

export async function getStaticProps({ locale }) {
  return {
    props: {
      messages: {
        ...require(`locales/${locale}/shared.json`),
      },
    },
  };
}
